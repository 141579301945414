<template>
  <div>
    <div
      class="modal fade"
      id="createCategoriesModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              Create Giftcard Category
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="card">
              <div class="card-body">
                <div>
                  <form @submit.prevent="CreateCategory">
                    <div class="form-group">
                      <label>Category Name</label>
                      <input
                        type="text"
                        required
                        class="form-control"
                        v-model="categoryName"
                        placeholder="Enter Category Name"
                      />
                    </div>
                    <div class="form-group">
                      <label>Details</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="details"
                        required
                        aria-describedby="emailHelp"
                        placeholder="Enter Details"
                      />
                    </div>
                    <div class="form-group">
                      <label>Rate</label>
                      <input
                        type="number"
                        class="form-control"
                        v-model="rate"
                        required
                        placeholder="Enter Rate"
                      />
                    </div>
                    <button
                      v-if="!loadingUpdate"
                      type="submit"
                      class="btnd btn-success text-light"
                    >
                      Update
                    </button>
                    <button
                      v-else
                      class="btnd btn-success text-light"
                      type="button"
                      disabled
                    >
                      <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Loading...
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btnd btn-success" data-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Calls from "@/Calls";
import axios from "axios";
export default {
  name: "AddCategoryModal",
  data() {
    return {
      loadingUpdate: false,
      categoryName: "",
      rate: undefined,
      details: "",
    };
  },

  methods: {
    async CreateCategory() {
      this.loadingUpdate = true;
      var message, type;
      await axios
        .post(
          `${Calls.baseUrl()}/admin/giftcard/category`,
          {
            categoryName: this.categoryName,
            details: this.details,
            rate: this.rate,
          },
          { headers: JSON.parse(localStorage["headers"]) }
        )
        .then((res) => {
          type = "success";
          message = res.data.msg;
        })
        .catch((err) => {
          type = "error";
          message = err.response.data.msg;
        });

      this.$toast.open({
        message,
        type,
        position: "top-right",
        duration: 2000,
        pauseOnHover: true,
      });

      this.loadingUpdate = false;
      if (type == "success") {
        setTimeout(() => {
          location.reload();
        }, 2300);
      }
    },
  },
};
</script>
